import * as React from 'react'
import {
  BlogHeaderSlice,
  BlogSearchBarSlice,
} from '../components/Slices'
import { Layout, Seo} from '../components/Layout'

const BlogPage = () => {
  const pageName = 'Blog'
  const breadcrumbList = [{ position: 1, name: pageName, path: 'blog' }]

  return (
    <Layout >
      <Seo title={pageName} pathname="blog" breadcrumbList={breadcrumbList} />
      <BlogHeaderSlice />
      <BlogSearchBarSlice />
    </Layout>
  )
}

export default BlogPage
